<template>
  <div class="search">
    <input placeholder="Search"/>
    <!-- <button class="filter"></button> -->
  </div>
</template>

<script>
export default {
  name: 'search',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
  .filter,.search input{
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .search{
    height: 40px;
    button{
      height: 32px;
      width: 32px;
      border: 0;
      background-color: #fff;
      outline: none;
    }
  }
  .search{
    display: flex;
    position: absolute;
    top: 17px;
    right: 160px;
    input{
      height: 40px;
      border: 0;
      background-color: rgba(120, 120, 120, 0.05);
      width: 344px;
      box-sizing: border-box;
      border-radius: 8px;
      background-image: url(../../../public/img/search.svg);
      background-position: 12px center;
      font-size: 14px;
      text-indent: 45px;
      &:active{
        border: 0;
        outline: none;
        background-image: none;
        text-indent: 8px;
      }
      &:focus{
        border: 0;
        outline: none;
        background-image: none;
        text-indent: 8px;
      }
    }
    input:focus::placeholder,input:active::placeholder{
      color: transparent;
    }
    .filter{
      background-image: url(../../../public/img/filter.svg);
      margin-left: 15px;
      height: 40px;
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
  and (min-height: 961px) and (orientation: portrait){
    .search{
    position: initial;
    width: 100%;
    input{
      width: 100%;
    }
  }
}
@media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
  .search{
    position: initial;
    input{
      width: 100%;
      text-align: left;
    }
  }
}
</style>
